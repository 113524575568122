import { createAction, props } from "@ngrx/store";
import { InductionLocation } from "./induction-location.model";

export enum InductionLocationActionType {
  GET_ALL_INDUCTION_LOCATIONS = '[Induction Location] Get All Induction Locations',
  GET_ALL_INDUCTION_LOCATIONS_SUCCESS = '[Induction Location] Get All Induction Locations :: Success',
  GET_INDUCTION_LOCATION = '[Induction Location] Get Induction Location',
  GET_INDUCTION_LOCATION_SUCCESS = '[Induction Location] Get Induction Location :: Success',
  CREATE_INDUCTION_LOCATION = '[Induction Location] Create Induction Location',
  CREATE_INDUCTION_LOCATION_SUCCESS = '[Induction Location] Create Induction Location :: Success',
  UPDATE_INDUCTION_LOCATION = '[Induction Location] Update Induction Location',
  UPDATE_INDUCTION_LOCATION_SUCCESS = '[Induction Location] Update Induction Location :: Success',
  RESET_INDUCTION_LOCATION_UPDATED = '[Induction Location] Reset Induction Location Updated',
  DELETE_INDUCTION_LOCATION = '[Induction Location] Delete Induction Location',
  DELETE_INDUCTION_LOCATION_SUCCESS = '[Induction Location] Delete Induction Location :: Success',
  RESET_INDUCTION_LOCATION_DELETED = '[Induction Location] Reset Induction Location Deleted',
}

export const getAllInductionLocations = createAction(InductionLocationActionType.GET_ALL_INDUCTION_LOCATIONS);
export const getAllInductionLocationsSuccess = createAction(
  InductionLocationActionType.GET_ALL_INDUCTION_LOCATIONS_SUCCESS,
  props<{ inductionLocations: InductionLocation[] }>()
);
export const getInductionLocation = createAction(InductionLocationActionType.GET_INDUCTION_LOCATION, props<{ id: string }>());
export const getInductionLocationSuccess = createAction(InductionLocationActionType.GET_INDUCTION_LOCATION_SUCCESS, props<{ inductionLocation: InductionLocation }>());
export const createInductionLocation = createAction(InductionLocationActionType.CREATE_INDUCTION_LOCATION, props<{ inductionLocation: InductionLocation }>());
export const createInductionLocationSuccess = createAction(
  InductionLocationActionType.CREATE_INDUCTION_LOCATION_SUCCESS,
  props<{ inductionLocation: InductionLocation }>()
);
export const updateInductionLocation = createAction(InductionLocationActionType.UPDATE_INDUCTION_LOCATION, props<{ inductionLocation: InductionLocation }>());
export const updateInductionLocationSuccess = createAction(
  InductionLocationActionType.UPDATE_INDUCTION_LOCATION_SUCCESS,
  props<{ inductionLocation: InductionLocation }>()
);
export const resetInductionLocationUpdated = createAction(InductionLocationActionType.RESET_INDUCTION_LOCATION_UPDATED);
export const deleteInductionLocation = createAction(InductionLocationActionType.DELETE_INDUCTION_LOCATION, props<{ id: number }>());
export const deleteInductionLocationSuccess = createAction(InductionLocationActionType.DELETE_INDUCTION_LOCATION_SUCCESS);
export const resetInductionLocationDeleted = createAction(InductionLocationActionType.RESET_INDUCTION_LOCATION_DELETED);
